import styled from "styled-components";

export const TemplateTopContentStyled = styled.div`
  padding: 50px 0 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;
  gap: 10px;
  .topContentImage {
    z-index: 1;
  }
  .title {
    position: relative;
    z-index: 2;
    width: 100%;
    color: ${(props) => props.theme.color?.core.white};
    text-align: center;
  }
  .subtitle {
    color: ${(props) => props.theme.color.core.white};
    position: relative;
    z-index: 2;
    max-width: 930px;
    font-size: 24px;
    text-align: center;
    line-height: 26px;
    padding: 0;
    margin-bottom: 16px;
  }

  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    height: 195px;
    padding: 40px 24px;
    gap: 2px;
    .title {
      width: 342px;
      padding: 0 24px;
      text-align: center;
      font-family: ${(props) => props.theme.font.barlow};
    }
    .subtitle {
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      font-family: ${(props) => props.theme.font.barlow};
      padding: 0 24px;
    }
  }
`;
