import styled from "styled-components";

export const SiblingNavigationStyled = styled.div`
  position: relative;
  background: ${(props) => props.theme.color.primary.coleslawWhite};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 187px;

  .navigationLinkText {
    color: ${(props) => props.theme.color.core.blue};
    font-family: ${(props) => props.theme.font.barlow};
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
  }

  .content {
    width: 420px;
    height: 67px;
    display: flex;
    justify-content: space-between;
    padding: 0 26px;
  }

  .siblingNavigationContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .navigationLink {
    font-size: 15px;
    color: ${(props) => props.theme.color.core.white};
    height: 36px;
    border-radius: 20px;
    background-color: ${(props) => props.theme.color.core.blue};
    cursor: pointer;
    width: 128px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  .navButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 128px;
    height: 36px;
    font-family: ${(props) => props.theme.font.barlow};
    font-weight: 700;
    font-size: 15px;
    color: ${(props) => props.theme.color.primary.coleslawWhite};
    line-height: 18px;
    gap: 0 2px;
  }
  .iconPrev {
    position: relative;
    width: 16px;
    height: 16px;
    .iconImgPrev {
      height: 16px;
      width: 16px;
    }
  }
  .iconNext {
    position: relative;
    width: 16px;
    height: 16px;
    transform: scaleX(-1);
    img {
      height: 16px;
      width: 16px;
    }
  }
  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    height: 131px;

    .content {
      width: 352px;
      height: 67px;
      padding: 0 20.44px;
    }

    .navButton {
      width: 139.11px;
      gap: 0 5px;
    }

    .iconPrev {
      width: 7.11px;
      height: 12.83px;
      img {
        height: 7.11px;
        width: 12.83px;
      }
    }
    .iconNext {
      width: 7.11px;
      height: 12.83px;
      margin-right: 5px;
      gap: 0 5px;
      img {
        height: 7.11px;
        width: 12.83px;
      }
    }
  }
`;
