import NextHead from "next/head";
import { NAME_LOGO_WITH_CHICKEN } from "assets/images";
import FAVICON from "public/favicon.png";

interface Props {
  title?: string;
  description?: string;
  favicon?: string;
  canonical?: string;
  jsonLd?: object;
  Title?: string;
  Description?: string;
  Favicon?: string;
  Canonical?: string;
  JsonLd?: object;
  imageURL?: string;
  ogURL?: string;
}

const Head = (props: Props) => {
  const title = props.title ?? props.Title;
  const description = props.description ?? props.Description;
  const canonical = props.canonical ?? props.Canonical ?? "";
  const jsonLd = props.jsonLd ?? props.JsonLd ?? "";
  const imageURL = props?.imageURL ?? NAME_LOGO_WITH_CHICKEN.src;

  return (
    <NextHead>
      <title>{title}</title>
      {description && <meta key="description" name="description" content={description} />}
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="theme-color" content="#041E42" />
      <link key="icon" rel="icon" href={FAVICON.src} />
      {props?.ogURL && <meta property="og:url" content={props?.ogURL} />}
      <meta property="og:site_name" content="Zaxbys" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageURL} itemProp="image" />
      <meta property="og:type" content="website" />
      <meta property="og:image:width" content="300" />
      <meta property="og:image:height" content="300" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageURL} />
      <meta property="og:type" content="article" />
      {canonical && <link key="canonical" rel="canonical" href={canonical} />}

      {jsonLd && (
        <script
          nonce={"f9959a0860a72cc3"}
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
          key="jsonld"
        />
      )}
    </NextHead>
  );
};

export default Head;
