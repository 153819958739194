import { Helmet } from "react-helmet";
import MarkdownComponent from "components/global/MarkdownComponent/MarkdownComponent";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import { LeadershipPerson as LeadershipPersonType } from "types/cms/leadership";
import LeadershipPersonImage from "../LeadershipPersonImage/LeadershipPersonImage";
import { LeadershipPersonStyled } from "./LeadershipPersonStyled";

interface Props {
  person: LeadershipPersonType;
  className?: string;
  withBio?: boolean;
}

const LeadershipPerson = ({ person, className, withBio }: Props) => {
  let name;
  let description;

  switch (person.Name) {
    case "Zach McLeroy":
      name = "Leadership - About Us - Zach McLeroy | Zaxbys";
      description =
        "Zach McLeroy is the Chairman and Founder of Zaxbys. Zach continues to grow a billion-dollar franchise that has been recognized since its beginning in 1990.";
      break;
    case "Bernard Acoca":
      name = "Leadership - About Us - Bernard Acoca | Zaxbys";
      description =
        "Bernard Acoca is the first incoming CEO of Zaxbys. He joined in January 2022 after serving in several leadership positions and is committed to driving growth.";
      break;
    case "Blake Bailey":
      name = "Leadership - About Us - Blake Bailey | Zaxbys";
      description =
        "Blake Bailey is the Chief Financial Officer at Zaxbys. Since 2000, Blake has been building the financial infrastructure to support a 900+ franchise operation.";
      break;
    case "Brenda Trickey":
      name = "Leadership - About Us - Brenda Trickey | Zaxbys";
      description =
        "Brenda Trickey is Zaxbys first-ever in-house General Counsel. Brenda is building a best-in-class legal department at Zaxbys.";
      break;
    case "Carl Mount":
      name = "Leadership - About Us - Carl Mount | Zaxbys";
      description =
        "Carl Mount is the Chief Supply Chain Officer at Zaxbys. Carl has over 25 years of experience in the food and beverage industry at multi-billion-dollar brands.";
      break;
    case "Donny Lau":
      name = "Leadership - About Us - Donny Lau | Zaxbys";
      description =
        "Donny Lau is the Chief Financial Officer at Zaxbys. Donny was most recently at Dollar General, where he served as Senior Vice President, Finance & Chief Strategy Officer.";
      break;
    case "Michelle Morgan":
      name = "Leadership - About Us - Michelle Morgan | Zaxbys";
      description =
        "Michelle Morgan is the first Chief People Officer at Zaxbys. Michelle has spent more than 20 years with Zaxbys and is instrumental in elevating its culture.";
      break;
    case "Mike Mettler":
      name = "Leadership - About Us - Mike Mettler | Zaxbys";
      description =
        "Mike Mettler is the Chief Development Officer at Zaxbys. Mike joined the company in May 2022 to establish a more robust franchising recruitment platform.";
      break;
    case "Mike Nettles":
      name = "Leadership - About Us - Mike Nettles | Zaxbys";
      description =
        "Mike Nettles is the Chief Digital and Technology Officer at Zaxbys. Mike aims to personalize the brand’s digital offerings and create a seamless guest experience.";
      break;
    case "Patrick Schwing":
      name = "Leadership - About Us - Patrick Schwing | Zaxbys";
      description =
        "Patrick Schwing is the Chief Marketing and Strategy Officer at Zaxbys. Patrick has vast professional experience in marketing and brand strategy.";
      break;
    case "Sharlene Smith":
      name = "Leadership - About Us - Sharlene Smith | Zaxbys";
      description =
        "Sharlene Smith is the Chief Operating Officer at Zaxbys. Sharlene joined in April 2022 and is focused on driving the brand’s national expansion.";
      break;
    default:
      break;
  }
  return (
    <LeadershipPersonStyled className={className}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{name}</title>
        <meta name={name} content={description} />
      </Helmet>

      {person.Image?.url && (
        <LeadershipPersonImage
          imageUrl={person.Image.url}
          altText={person.Image.alternativeText}
          className="personImage"
          identifier={person.Name || ""}
        />
      )}
      <div className="personInfo">
        {person.Name && (
          <Paragraph variant="secondaryParagraph4" className="personName">
            {person.Name}
          </Paragraph>
        )}
        {person.Position && (
          <Paragraph variant="paragraphHeading1" className="personPosition">
            {person.Position}
          </Paragraph>
        )}
      </div>
      {withBio && person.Description && <MarkdownComponent markdown={person.Description} className="personDescription" />}
      <div className="background" />
    </LeadershipPersonStyled>
  );
};

export default LeadershipPerson;
