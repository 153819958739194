import styled from "styled-components";

export const LogisticFooterStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${(props) => props.theme.responsive?.max.lg} {
    flex-direction: column;
    height: 100%;
  }
`;

export const LogisticFooterSide = styled.div<{
  position: "right" | "left" | string;
}>`
  background: ${({ position, theme }) => (position === "right" ? theme.color?.core.white : theme.color?.primary.foundersBlue)};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px;
  .logistical-footer-left {
    color: ${({ theme }) => theme.color?.core.white};
    text-align: center;
    max-width: 366px;
  }
  .logistical-left-paragraph {
    text-align: center;
    color: ${({ theme }) => theme.color?.core.white};
    margin-top: 30px;
    max-width: 440px;
  }
  .logistical-left-button {
    margin-top: 28px;
    width: auto;
    padding: 0 34px;
    min-height: 44px;
  }
  .logistical-footer-right {
    color: ${({ theme }) => theme.color?.primary.foundersBlue};
    text-align: center;
    max-width: 366px;
  }
  .logistical-right-paragraph {
    text-align: center;
    color: ${({ theme }) => theme.color?.primary.foundersBlue};
    margin-top: 30px;
    max-width: 440px;
  }
  .logistical-right-button {
    margin-top: 28px;
    width: auto;
    padding: 0 34px;
    min-height: 44px;
  }
  ${(props) => props.theme.responsive?.max.lg} {
    flex-direction: column;
    padding: 48px 24px;
    .logistical-footer-left,
    .logistical-footer-right {
      max-width: 292px;
    }
    .logistical-right-paragraph,
    .logistical-left-paragraph {
      margin-top: 20px;
      max-width: 290px;
      text-align: center;
    }
    .logistical-right-button,
    .logistical-left-button {
      margin-top: 18px;
      min-height: 36px;
      padding: 0px 24px;
      font-size: 15px;
      font-weight: 700;
      line-height: 18px;
      border-width: 0;
    }
  }
`;
