import { useRouter } from "next/router";
import Button from "components/atomic-components/atoms/button/Button";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import Paragraph from "../../atoms/typography/Paragraphs/Paragraph";
import { LogisticFooterStyled, LogisticFooterSide } from "./LogisticFooterStyled";

interface Content {
  title: string;
  description: string;
  CTA: string;
  CTALink: string;
  onClick?: (position: string) => void;
}

interface Props {
  leftContent: Content;
  rightContent: Content;
  className?: string;
}

const LogisticFooter = ({ leftContent, rightContent, className }: Props) => {
  const router = useRouter();
  const handleOnBtnClickAction = (url: string) => {
    router.push(url);
  };

  const renderFooterSide = (content: Content, position: "left" | "right") => (
    <LogisticFooterSide position={position}>
      {content?.title && (
        <Headings
          variant="headingMedium"
          fontSize={36}
          fontWeight={700}
          mobileFontSize={24}
          mobileLineHeight={24}
          className={`logistical-footer-${position}`}
        >
          {content?.title}
        </Headings>
      )}
      {content?.description && (
        <Paragraph variant={`paragraphLogistics1`} className={`logistical-${position}-paragraph`}>
          {content?.description}
        </Paragraph>
      )}
      {content?.CTA && (
        <Button
          variant={`primary${position === "left" ? "White" : "Blue"}`}
          className={`logistical-${position}-button`}
          onClick={() => (content?.onClick ? content?.onClick(position) : handleOnBtnClickAction(content?.CTALink))}
        >
          {content?.CTA}
        </Button>
      )}
    </LogisticFooterSide>
  );

  return (
    <LogisticFooterStyled className={className}>
      {renderFooterSide(leftContent, "left")}
      {renderFooterSide(rightContent, "right")}
    </LogisticFooterStyled>
  );
};

export default LogisticFooter;
