import styled from "styled-components";

export const LeadershipPersonStyled = styled.div`
  position: relative;
  display: grid;
  justify-content: start;
  max-width: 780px;
  background-color: ${(props) => props.theme.color.core.white};
  padding: 32px 60px;
  margin: 60px auto 0;

  > *:not(.background) {
    position: relative;
    z-index: 2;
  }

  .personInfo {
    grid-area: 1 / 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 32px;

    .personName {
      font-weight: 700;
      font-size: 40px;
      line-height: 48px;
      margin-bottom: 8px;
    }
  }

  .personImage {
    grid-area: 1 / 1 / span 2;
  }

  .personDescription {
    align-self: stretch;
    text-align: left;
    grid-area: 2 / 2 / 3 / span 2;
    padding: 31px 0 0 32px;

    p {
      font-family: ${(props) => props.theme.font.barlow};
      color: ${(props) => props.theme.color.primary.foundersBlue};
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }

  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 342px;
    padding: 20px 20px 0 20px;
    border-radius: 5px;
    gap: 20px;
    margin: 32px 24px 0 24px;

    .personImage {
      .image-person-container {
        width: 100px;
        height: 100px;
      }
    }
    .personInfo {
      margin: 0;
      align-items: center;
      gap: 8px;
      .personName {
        font-family: ${(props) => props.theme.font.barlow};
        font-size: 20px;
        line-height: 20px;
        text-transform: uppercase;
        margin: 0;
      }
      .personPosition {
        font-size: 12px;
        line-height: 14px;
      }
    }
    .personDescription {
      padding: 0;
      p {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
      }
    }
  }
`;
