import styled from "styled-components";

export const LeadershipPersonImageStyled = styled.div<{
  imagePatternId?: string;
}>`
  .image-person-container {
    position: relative;
    height: 200px;
    width: 200px;
  }
  .image-person {
    border-radius: 5px;
  }
`;
