import styled from "styled-components";

export const LeadershipStyled = styled.div`
  background-color: ${(props) => props.theme.color.primary.coleslawWhite};

  .leadershipPeople {
    background-color: ${(props) => props.theme.color.core.white};
    max-width: 780px;
    padding: 32px;
    margin: 60px auto;
    display: flex;
    flex-direction: column;
    gap: 32px;
    .leadershipPerson {
      padding: 0;
      margin: 0;
      .personInfo {
        grid-area: 2/2;
      }
    }
  }

  .dualCallouts {
    position: relative;
    z-index: 2;
  }

  .subNav {
    background: white;
    z-index: 1;
    position: relative;
    bottom: 10px;
  }

  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    .leadershipPeople {
      padding: 32px;
      margin: 32px 24px;
      border-radius: 5px;
    }
  }
`;
