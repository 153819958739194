import CMSImage from "components/global/CMSImage/CMSImage";
import { LeadershipPersonImageStyled } from "./LeadershipPersonImageStyled";

interface Props {
  imageUrl: string;
  identifier?: string;
  altText?: string | null;
  className?: string;
}

const LeadershipPersonImage = ({ imageUrl, altText, className }: Props) => {
  return (
    <LeadershipPersonImageStyled className={className}>
      <div className="image-person-container">
        <CMSImage src={imageUrl} alt={altText || "Person"} layout="fill" className="image-person" />
      </div>
    </LeadershipPersonImageStyled>
  );
};

export default LeadershipPersonImage;
