import Image from "next/image";
import { useRouter } from "next/router";
import { CMSLink, Nullable, SiblingNavigation as SiblingNavigationType } from "types/cms/cms-types";
import { IC_ARROW_THIN_WHITE } from "assets/images";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import Button from "components/atomic-components/atoms/button/Button";
import { SiblingNavigationStyled } from "./SiblingNavigationStyled";

interface Props {
  siblingNavigationData: SiblingNavigationType;
  className?: string;
}

const SiblingNavigation = ({ siblingNavigationData, className }: Props) => {
  const router = useRouter();
  const onNavButtonClick = (link: Nullable<string>) => {
    router.push(link ?? "#");
  };
  const getSiblingNavigationJsx = (siblingNavigationLink: CMSLink, direction: "Previous" | "Next") => {
    return (
      <>
        {siblingNavigationLink.Name && (
          <Paragraph variant="primaryParagraph1" fontSize={15} fontWeight={700} className="navigationLinkText">
            {siblingNavigationLink.Name}
          </Paragraph>
        )}
        <div className="navigationLink">
          <Button variant="primaryBlue" className="navButton" onClick={() => onNavButtonClick(siblingNavigationLink?.Link)}>
            {direction === "Previous" && (
              <div className="iconPrev">
                <Image src={IC_ARROW_THIN_WHITE} alt="IC" layout="fill" className="iconImgPrev" />
              </div>
            )}
            {direction}
            {direction === "Next" && (
              <div className="iconNext">
                <Image src={IC_ARROW_THIN_WHITE} alt="IC" layout="fill" className="iconImgNext" />
              </div>
            )}
          </Button>
        </div>
      </>
    );
  };

  return (
    <SiblingNavigationStyled className={className}>
      <div className="content">
        <div className="siblingNavigationContent">
          {siblingNavigationData.Previous && getSiblingNavigationJsx(siblingNavigationData.Previous, "Previous")}
        </div>
        <div className="siblingNavigationContent">
          {siblingNavigationData.Next && getSiblingNavigationJsx(siblingNavigationData.Next, "Next")}
        </div>
      </div>
    </SiblingNavigationStyled>
  );
};

export default SiblingNavigation;
