import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setIsLoading } from "redux/reducer/Global/GlobalAction";
import { apiCall } from "src/apis/api";
import { CMS_LEADERSHIP } from "api/endpoints";
import TemplateTopContent from "components/global/TemplateTopContent/TemplateTopContent";
import LogisticFooter from "components/atomic-components/molecules/LogisticFooter/LogisticFooter";
import SiblingNavigation from "components/atomic-components/molecules/SiblingNavigation/SiblingNavigation";
import { LeadershipCMSData } from "types/cms/leadership";
import Link from "components/global/Link/Link";
import LeadershipPerson from "components/Leadership/LeadershipPerson/LeadershipPerson";
import { formattedLogisticFooter } from "utils/formattingCMSMessage";
import { LeadershipStyled } from "./LeadershipStyled";

const Leadership = () => {
  const dispatch = useDispatch();
  const [leadershipData, setLeadershipData] = useState<LeadershipCMSData | null>(null);

  useEffect(() => {
    // Fetch Leadership data from CMS API
    const fetchLeadershipData = async () => {
      dispatch(setIsLoading(true));
      const data = await apiCall({
        requestType: "GET",
        apiEndPoint: `${CMS_LEADERSHIP}`,
      });
      dispatch(setIsLoading(false));
      setLeadershipData(data.response);
    };

    fetchLeadershipData();
  }, []);

  return (
    <>
      {leadershipData ? (
        <LeadershipStyled>
          {leadershipData.Head?.Background?.url && leadershipData.Head.Title && (
            <TemplateTopContent
              className="topContentImage"
              title={leadershipData.Head.Title}
              bgUrl={leadershipData.Head.Background.url}
              bgAlt={leadershipData.Head.Background.alternativeText || "Background"}
              subtitle={leadershipData.Head.Subtitle}
            />
          )}
          <div className="leadershipPeople">
            {leadershipData.People?.map((person, index) => (
              <Link key={index} to={`/about/leadership/${person.Name?.split(" ").join("-")}`}>
                <LeadershipPerson className="leadershipPerson" person={person} />
              </Link>
            ))}
          </div>
          {leadershipData.DualCallouts && (
            <LogisticFooter
              leftContent={formattedLogisticFooter(leadershipData?.DualCallouts, "left")}
              rightContent={formattedLogisticFooter(leadershipData?.DualCallouts, "right")}
              className="logisticFooter"
            />
          )}
          <div className="subNav">
            {leadershipData.Navigation && <SiblingNavigation siblingNavigationData={leadershipData.Navigation} />}
          </div>
        </LeadershipStyled>
      ) : null}
    </>
  );
};

export default Leadership;
