import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import CMSImage from "components/atomic-components/molecules/CMSImage/CMSImage";
import { Nullable } from "types/cms/cms-types";
import { TemplateTopContentStyled } from "./TemplateTopContentStyled";

interface Props {
  bgUrl?: Nullable<string>;
  bgAlt?: Nullable<string>;
  title?: Nullable<string>;
  subtitle?: Nullable<string>;
  className?: string;
}

const TemplateTopContent = ({ bgUrl, bgAlt, title, subtitle, className }: Props) => {
  return (
    <TemplateTopContentStyled className={className}>
      {bgUrl && <CMSImage src={bgUrl} layout="fill" className="topContentImage" alt={title || ""} objectPosition="bottom" />}
      <Headings variant="primaryHeading2" mobileFontWeight={700} className="title">
        {title}
      </Headings>
      {subtitle && (
        <Paragraph variant="secondaryParagraph4" className="subtitle">
          {subtitle}
        </Paragraph>
      )}
    </TemplateTopContentStyled>
  );
};

export default TemplateTopContent;
